.confirmation-outer-container {
    height: 100%;
    background-color: #FAFAFA;
    flex: 1;
}

.confirmation-inner-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin-top: 40px;
    margin-bottom: 40px;
    width: 100%;
}

.left-half {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-left: 80px;
}

.divider {
    display: flex;
    flex: 0.1;
    margin-left: 10px;
    margin-right: 10px;
    border-right: 1px solid #E0E0E0;
}

.right-half {
    display: flex;
    flex: 4;
    flex-direction: column;
    align-content: center;
    align-items: center;
    overflow: auto;
    right: 0px;
    margin-right: 10px;
}

.right-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
}

.MainTitle {
    font-family: Arial;
    text-align: left;
    font-size: 24px;
    font-weight: bold;
    line-height: 28px;
    text-transform: capitalize;
}

.Address {
    font-size: 12px;
    line-height: 14px;
    font-family: 'Roboto', sans-serif;
    text-transform: capitalize;
    margin-top: 3px;
    color: #BDBDBD;
}

.WaitlistDetailsBox {
    min-width: 286px;
    border-radius: 5px;
    background-color: #FFFFFF;
    padding: 20px;
    margin-top: 30px;
}

.WaitlistDetailsTitleText {
    font-family: Arial;
    text-align: left;
    font-size: 14px;
    line-height: 16px;
    font-weight: bold;
    color: #000000;
}

.WaitlistDetailsCustomerText {
    font-family: 'Roboto', sans-serif;
    text-align: left;
    font-size: 12px;
    line-height: 14px;
    color: #000000;
}

.congrats {
    font-size: 18px;
    font-weight: bold;
    font-family: Arial;
    line-height: 21px;
    margin-top: 15px;
}

.check-in-time {
    font-size: 14px;
    font-weight: bold;
    font-family: Arial;
    margin-top: 8px;
    color: #CC2328;
}