
ToggleButton{
    border: 1px solid lightgray !important;
}
#requeststyle{
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid lightgray;
    background: white;
    border-radius: 20% !important;
    color: black;
    margin: 1px 7px 1px;
    width: 12vmin !important;
    height: 12vmin !important;
    
}


#button-parent .button-deselected {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid lightgray;
    background: white;
    border-radius: 20% !important;
    color: black;
    margin: 1px 7px 1px;
    width: 12vmin !important;
    height: 12vmin !important;
    box-shadow: 0 0 0 0;
}

#button-parent .button-selected {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2.4px solid #cc2529;
    background: rgb(204, 37, 41, 0.3);
    border-color: #cc2529;
    border-radius: 20% !important;
    color: black;
    margin: 1px 7px 1px;
    width: 12vmin !important;
    height: 12vmin !important;
    box-shadow: 0 0 0 0;
}

#button-parent .button-text {
    font-size: 12px;
    margin-left: auto;
    margin-right: auto;
}
#button-parent .button-image {
    margin-left: auto;
    margin-right: auto;
}

#button-style {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid lightgray;
    background: white; 
    border-radius: 100% !important;
    color: black;
    width: 8vmin !important;
    height: 8vmin !important;
    font-size: 20px;
}

#button-style-clear {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid clear;
    background: white; 
    border-radius: 100% !important;
    color: black;
    width: 8vmin !important;
    height: 8vmin !important;
    font-size: 20px;
}

.modal-content {
    margin: 10rem auto;
}
#red-button {
    width: 30%;
    font-size: 10px;
    background-color: #cc2529;
    border-radius: 30px;
    color: white;
}

#margin-auto {
    margin: auto;
}
#toggleButtonGroup .focus {
    background: #cc2529 !important;
    color: white !important;
}
#toggleButtonGroup .active {
    background: #cc2529 !important;
    color: white !important;
}
@media only screen and (max-width: 720px){
    #requeststyle {
        margin: auto !important;
    }
}

.spacer {
    margin: auto;
    margin-top: 10px;
    margin-bottom: 10px;
}

.spacer-left {
    margin-left: auto;
}

.spacer-right {
    margin-right: auto;
}

#modal-button-container {
    margin: 10px;
}