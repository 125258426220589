.CakeWalkIcon {
  width: 100%;
  height: 58px;
  left: 0px;
  top: 0px;
  padding: 10px;
  background: #FFFFFF;
}

.CakeWalkLink {
  position: absolute;
  right: 1%;
  padding: 20px;
  font-family: ArialMT;
  font-size: 11px;
  color: #EB5757;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@media only screen and (max-width: 720px){
  .CakeWalkLink {
    display: none;
  }
}
