.RestaurantOuter {
    display: flex;
    justify-content: center;
    background-color: #F5F5F5;
    flex: 1;
  }
  
  .RestaurantInner {
    max-width: 1040px;
    margin-left: 60px;
    margin-right: 60px;
  }
  
  .RestaurantListContainer {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
  }
  
  .RestaurantContainer {
    background: white;
    margin-right: 20px;
  }
  
  .RestaurantBox {
    width: 250px;
    margin-right: 10px;
  }
  
  .ImageBox {
    width: 100%;
  }
  
  .DataBox {
    height: 100px;
    text-align: left;
    color: black;
    text-decoration: none;
  }
  
  .Heading {
    text-transform: uppercase;
    font-size: 18px;
    text-align: left;
    color: gray;
    margin-top: 20px;
  }
  
  .Title {
    font-weight: 700;
    font-size: 20px;
    color: black;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  
  .InfoBox {
    display: flex;
    align-items: center;
  }
  
  .InfoText {
    font-size: 12px;
    color: black;
  }
  
  .WaitTime {
    font-size: 12px;
    color: forestgreen;
  }
  
  .PartyCount {
    font-size: 12px;
    color: gray;
  }