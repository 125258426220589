#textbox {
  display: flex;
  width: 85vmin;
  margin: 3%;
  border-radius: 5% / 50%;
  height: calc(20px + 5vmin);
  border-color: lightgray;
  border-style: solid;
  padding-left: 3%;
  outline: none;
}

#numberInput {
  display: flex;
  width: 85vmin;
  margin: 3%;
  border-radius: 5% / 50%;
  height: calc(20px + 5vmin);
  border-color: lightgray;
  border-style: solid;
  padding-left: 3%;
  outline: none;
}

#createticket {
  color: white;
  font-weight: bold;
  text-align: center;
  align-content: center;
  width: 85vmin;
  margin: 3%;
  height: calc(20px + 5vmin);
  border-color: lightgray;
  border-style: solid;
  padding-left: 3%;
  background-color: #cc2529;
}

#main {
  display:flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0vmin 0.5vmin 0.5vmin;
  /* margin-bottom: 30px; */
}

#right{
  position: relative;
  flex-wrap: wrap;
  padding-top: 6vmin;
  border: 1px solid lightgray;
  width: 60%;
}

#left{
  position: relative;
  flex-wrap: wrap;
  padding-top: 6vmin;
  width: 40%;
  text-align: left;
  /* padding-right: 5vmin; */
  border: 1px solid lightgray;
}

#locationinfo{
  margin: 50px;
}



@media only screen and (max-width: 1024px){
  #main {
    justify-content: space-between;
  }

  #left {
    border: none;
    width: 100%;
    text-align: left;
    order: 1;
    padding-top: 0vmin;
  }
  
  #right {
    border: none;
    width: 100%;
    order: 2;
  } 

  #createticket {
    margin: 1% auto 20%;
    width: 80%;
    font-size: 10px;
    background-color: #cc2529;
  }

  #map {
    display: none;
  }

  #locationinfo{
    padding-left: 0vmin;
    margin: 3vmin;
  }

  #address{
    font-size: 12px;
  }
}

/* } */

#subheader{
  padding-left: 4vmin;
  font-size: calc(10px + 1.5vmin);
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;

}


.long-btn{
  display: block;
  width: 40%;
  border-radius: 5% / 50% !important;
  height: calc(20px + 5vmin);
  margin-bottom: 3%;
  margin-left: 30%;
  margin-right: 30%;
}

p {
  text-align: left;
}

.RestName {
  font-weight: bold;
  font-size: 27px;
}

.address {
  color: #999;
}

#TOS { /*Terms of Service p */
  padding: 0px 35px 20px;
  font-family: calibri;
  color: gray;
  font-size: 12px;
}

.Title {
  font-weight: 700;
  color: black;
  font-size: 18px;
}

.Subtitle {
  color: black;
  font-size: 12px;
}