
#toggleButtonGroup .focus {
    background: #cc2529 !important;
    color: white !important;
}
#toggleButtonGroup .active {
    background: #cc2529 !important;
    color: white !important;
}

#radiostyle{
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid lightgray;
    background: white; 
    border-radius: 100% !important;
    color: black;
    margin: 1vmin 1vmin 0vmin;
    width: 8vmin !important;
    height: 8vmin !important;
}
#radiostyle:hover{
    background: #cc2529;
    color: white;
}


@media only screen and (max-width: 720px){
    #radiostyle {
        height: 11vmin !important;
        width: 11vmin !important;
    }
}

@media only screen and (max-width: 500px){
    #radiostyle {
        height: 13vmin !important;
        width: 13vmin !important;
        /* margin-left: auto;
        margin-right: auto; */
        margin: 0vmin 0.5vmin 0vmin;
    }
}